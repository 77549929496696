<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? ($t('cip.plat.wfDesign.button.title.editHeadTitle')) : ($t('cip.plat.wfDesign.button.title.addHeadTitle'))"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>

      <form-layout
        :column="option.column"
        :dataForm.sync="dataObj"
        ref="formLayout">
      </form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, add, getDetail} from "@/api/plugin/workflow/button";

export default {
  components: {FormLayout, HeadLayout},

  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      dataObj: {},
      option: {
        dialogType: 'drawer',
        size: 'mini',
        align: 'center',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        searchMenuSpan: 6,
        border: true,
        addBtn: true,
        index: true,
        viewBtn: true,
        editBtn: true,
        delBtn: true,
        selection: true,
        column: [
          {
            label: this.$t('cip.plat.wfDesign.button.field.buttonKey'),
            prop: "buttonKey",
            maxlength: 64,
            showWordLimit: true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.button.field.buttonKey`),
              trigger: "blur",
            }, {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
          },

          {
            label: this.$t('cip.plat.wfDesign.button.field.name'),
            prop: "name",
            maxlength: 64,
            showWordLimit: true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.button.field.name`),
              trigger: "blur"
            } ,{
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
          },
          {
            label: this.$t('cip.plat.wfDesign.button.field.display'),
            prop: "display",
            type: 'select',
            labelWidth: 150,
            value: true,
            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.button.field.yes'),
            //   value: true
            // }, {
            //   label: this.$t('cip.plat.wfDesign.button.field.no'),
            //   value: false
            // }],
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_yes",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.button.field.display`),
              trigger: "change"
            }],
          },
          {
            label: this.$t(`cip.plat.wfDesign.button.field.sort`),
            prop: "sort",
            type: 'number',
            minRows: 0,
            maxRows: 1000000000,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.wfDesign.button.field.sort`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.plat.wfDesign.button.field.status`),
            prop: 'status',
            type: 'select',
            // dicData: [{
            //   label: this.$t(`cip.plat.wfDesign.button.field.available`),
            //   value: 1
            // }, {
            //   label: this.$t(`cip.plat.wfDesign.button.field.forbidden`),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.button.field.status`),
              trigger: "change"
            }],
          },
        ]
      },
    }
  },
  created() {
    if (this.$route.query.type == 'edit') {
      this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
    }
  },
  methods: {
    checkLength(rule,value,callback){
      let len = value.match(/[^ -~]/g) == null ? value.length : value.length + value.match(/[^ -~]/g).length
      if(len > 255){
        return callback( new Error('长度不能超过255位'))
      }
      return  callback
    },
    initData(id){
      getDetail(id).then(res => {
        const {data} = res.data;
        this.dataObj = data;
      })
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          // this.$loading()
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            // this.$loading().close()
            if (type == 'back') {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.dataObj = res.data.data
            this.initData(this.dataObj.id)
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
